import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../layouts'

import AboutSidenav from "../../partials/about-sidenav"
import partners from "../../data/partners.yaml"

import '../../scss/about.scss'

const AboutManagementPage = ({data}) => {
  return (
    <Layout>
      <div className="about partners">

        <Helmet>
          <title>DigiWells – Acknowledgements</title>
          <meta property="og:title" content="DigiWells – Acknowledgements" />
          <meta name="description" content="Use the acknowledgement below when publishing work that has been fully or partially funded by DigiWells, or relates to the DigiWells consortium in any other manner." />
          <meta property="og:description" content="Use the acknowledgement below when publishing work that has been fully or partially funded by DigiWells, or relates to the DigiWells consortium in any other manner." />
          {
            /*
            <meta name="image" content={image} />
            <meta property="og:image" content={image} />
            */
          }
        </Helmet>

        <div className="container">
          <AboutSidenav />

          <div className="about-content">
            <div className="about-content-text">
              <h2 className="page-title">Acknowledgements</h2>
              <p><a href='/about/all-logos-plain/'>--== Get all logos for your slide here ==--</a></p>

              <p>
              Use the acknowledgement below when publishing work that has been fully or partially funded by DigiWells, or relates to the DigiWells consortium in any other manner. Publications must be registered in <a href="https://app.cristin.no/">the Cristin database</a> referring to Research Council of Norway (NFR) project number 309589.
              </p>
              <h3>Acknowledgements</h3>
              <p>
              This work is part of the Center for Research-based Innovation DigiWells: Digital Well Center for Value Creation, Competitiveness and Minimum Environmental Footprint (NFR SFI project no. 309589, https://DigiWells.no). 
              The center is a cooperation of NORCE Norwegian Research Centre, the University of Stavanger, the Norwegian University of Science and Technology (NTNU), and the University of Bergen. 
              It is funded by 
              {
                partners.industrialPartners &&
                partners.industrialPartners.map(function(partner) {
                  return (
                    " " + partner.company + ", "
                  )
                })
              }
              {/* Aker BP, ConocoPhillips, Equinor, Lundin, TotalEnergies, and Wintershall Dea. */}
              and the Research Council of Norway.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default AboutManagementPage
